import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[rpIntegerOnly]',
  standalone: true,
})
export class IntegerOnlyDirective {
  @HostListener('input', ['$event'])
  onInputChange(event: InputEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;
    const inputValue = event.data;

    if (!inputValue) return;

    const integerRegExp = /^(0|[1-9]\d*)$/;
    const firstNumber = currentValue.slice(0, 1);

    if (!integerRegExp.test(currentValue) || (Number(firstNumber) === 0 && Number(currentValue))) {
      inputElement.value = currentValue.slice(1);
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const inputElement = event.target as HTMLInputElement;
    const pastedData = event.clipboardData?.getData('text');
    if (!pastedData) return;

    const currentValue = inputElement.value;
    const integerRegExp = /^([1-9]\d*)$/;

    if (!integerRegExp.test(currentValue) || pastedData.startsWith('0') || pastedData === '0') {
      event.preventDefault();
    }
  }
}
