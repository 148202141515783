import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

import { Statuses } from './models';

@Component({
  selector: 'rp-status',
  standalone: true,
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class StatusComponent {
  type = input.required<Statuses>();
  label = input.required<string>();
}
