import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { Validation } from './enums/validation.enum';

export function confirmPasswordValidator(
  newPasswordKey: string,
  confirmPasswordKey: string,
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as FormGroup;
    const newPassword = formGroup.get(newPasswordKey)?.value;
    const confirmPassword = formGroup.get(confirmPasswordKey)?.value;

    if (newPassword === confirmPassword) {
      formGroup.controls[confirmPasswordKey]?.setErrors(null);

      return null;
    }

    formGroup.controls[confirmPasswordKey]?.setErrors({ [Validation.PasswordNotMatch]: true });

    return { [Validation.PasswordNotMatch]: true };
  };
}
