<div @slideOut @slideIn class="toast" [ngClass]="toast().type">
  <div class="toast__wrapper">
    <div class="toast__header">
      <div class="toast__title">
        @if (toast().icon) {
          <rp-icon [iconName]="toast().icon || ''" />
        }

        <p>{{ toast().title | translate }}</p>
      </div>

      <div class="toast__close">
        <rp-icon (click)="close()" iconName="reset" />
      </div>
    </div>

    <div class="toast__text">{{ toast().text | translate }}</div>
  </div>
</div>
