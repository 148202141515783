import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { passwordMinLength } from '@rp/shared/models';

import { Validation } from './enums/validation.enum';

export const passwordMinLengthValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return control.value && control.value.length < passwordMinLength
    ? { [Validation.PasswordMinLength]: true }
    : null;
};
