<div class="rp-spinner" [ngClass]="size()">
  <svg class="rp-spinner__circle" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
    <g clip-path="url(#clip0_3_10965)">
      <path d="M32 57.6V64C14.33 64 0 49.67 0 32H6.4C6.4 46.14 17.86 57.6 32 57.6Z" fill="url(#paint0_linear_3_10965)" />
      <path d="M64 32C64 49.67 49.67 64 32 64V57.6C46.14 57.6 57.6 46.14 57.6 32H64Z" fill="url(#paint1_linear_3_10965)" />
      <path d="M64 32H57.6C57.6 17.86 46.14 6.4 32 6.4V0C49.67 0 64 14.33 64 32Z" fill="url(#paint2_linear_3_10965)" />
      <path d="M32 0V6.4C17.86 6.4 6.4 17.86 6.4 32H0C0 14.33 14.33 0 32 0Z" fill="url(#paint3_linear_3_10965)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_3_10965" x1="-1.71" y1="33.71" x2="30.29" y2="65.71" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0.5" offset="0" />
        <stop offset="1" stop-color="white" stop-opacity="0.25" />
      </linearGradient>
      <linearGradient id="paint1_linear_3_10965" x1="33.71" y1="65.71" x2="65.71" y2="33.71" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0.25" offset="0" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient id="paint2_linear_3_10965" x1="33.71" y1="-1.71" x2="65.71" y2="30.29" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0.75" offset="0" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <linearGradient id="paint3_linear_3_10965" x1="-1.71" y1="30.29" x2="30.29" y2="-1.71" gradientUnits="userSpaceOnUse">
        <stop stop-color="white" stop-opacity="0.5" offset="0" />
        <stop offset="1" stop-color="white" stop-opacity="0.75" />
      </linearGradient>
      <clipPath id="clip0_3_10965">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
</div>
