<div class="accordion__header" (click)="toggleAccordion()">
  <div class="accordion__header_top">
    <ng-content select="[accordionHeader]" />
    <rp-icon [ngClass]="{ accordion__icon_active: isExpanded() }" [iconName]="isExpanded() ? icons.ChevronUp : icons.ChevronDown" />
  </div>
  <div class="accordion__header_bottom">
    <ng-content select="[accordionSubHeader]" />
  </div>
</div>
<div class="accordion__content" [@accordionContent]="isExpanded() ? 'open' : 'close'">
  <ng-content select="[accordionContent]" />
</div>
