/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { ManagerPayload, ManagersResponse } from '@rp/shared/models';

import { ManagersProvider } from './managers.provider';

function ManagersResponse(itemsCount: number): ManagersResponse {
  return {
    managers: new Array(itemsCount).fill(0).map((_, index) => ({
      id: index + 100,
      fullName: `John Doe ${index + 1}`,
      email: `john.doe ${index + 10}@gmail.com`,
      messengers: [
        { messengerId: 1, nickname: '@john' },
        { messengerId: 2, nickname: 'john_777' },
        { messengerId: 3, nickname: 'john_d' },
        { messengerId: 4, nickname: '@john_doe' },
      ],
    })),
    itemsCount,
  };
}

@Injectable()
export class MockManagersProvider implements ManagersProvider {
  getManagers(params?: string): Observable<ManagersResponse> {
    return of(ManagersResponse(22));
  }

  createManager(payload: ManagerPayload): Observable<null> {
    return of(null);
  }

  updateManager(id: number, payload: ManagerPayload): Observable<null> {
    return of(null);
  }
}
