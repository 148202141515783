import { inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';

@Injectable()
export class IconService {
  private _matIconRegistry = inject(MatIconRegistry);
  private _domSanitizer = inject(DomSanitizer);

  registerIcons(): void {
    this._matIconRegistry.addSvgIconResolver(this._iconResolver.bind(this) as IconResolver);
  }

  private _iconResolver(name: string): SafeResourceUrl {
    return this._domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`);
  }
}
