import { Directive, TemplateRef, ViewContainerRef, inject, input, effect } from '@angular/core';

import { SpinnerComponent } from '../spinner.component';
import { SpinnerSize } from '../models';

/**
 * Directive to replace view with an inline spinner
 */
@Directive({
  selector: '[rpSpinner]',
  standalone: true,
})
export class SpinnerDirective<T> {
  rpSpinner = input.required<boolean>();

  rpSpinnerSize = input<SpinnerSize>();

  /**
   * Unset by default, this forces it to evaluate upon first render.
   */
  private _isSpinning: boolean = null;

  private _templateRef = inject(TemplateRef<T>);
  private _viewContainer = inject(ViewContainerRef);

  constructor() {
    effect(() => {
      const condition = this.rpSpinner();

      if (condition !== this._isSpinning) {
        this._isSpinning = null;
        this._viewContainer.clear();
        this._isSpinning = condition;

        if (condition) {
          this._addSpinner();
        } else {
          this._viewContainer.createEmbeddedView(this._templateRef);
        }
      }
    });
  }

  private _addSpinner() {
    const componentRef = this._viewContainer.createComponent(SpinnerComponent);

    componentRef.setInput('size', this.rpSpinnerSize());
  }
}
