@if (data().length === 0) {
  <rp-table-placeholder [title]="titlePlaceholder() | translate" [subtitle]="subtitlePlaceholder() | translate">
    <ng-content select="[underPlaceholder]" />
  </rp-table-placeholder>
} @else {
  <div class="table-mobile">
    <table mat-table [dataSource]="dataSource()">
      <ng-container matColumnDef="cell">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <ng-container [ngTemplateOutlet]="template()" [ngTemplateOutletContext]="{ data: element.cell }" />
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div class="table-mobile mat-mdc-row content-row">
      <ng-content />
    </div>

    <mat-paginator
      [length]="itemsLength()"
      [pageSize]="pageSize()"
      [pageIndex]="pageIndex()"
      [hidePageSize]="hidePageSize()"
      [pageSizeOptions]="pageSizeOptions()"
      [showFirstLastButtons]="showFirstLastButtons()"
      (page)="onPageChange($event)"
    >
    </mat-paginator>
  </div>
}
