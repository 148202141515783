import { PaymentMethod, PaymentMethodRest } from '@rp/shared/models';

const toRest = (paymentMethod: PaymentMethod): PaymentMethodRest => ({
  walletName: paymentMethod.walletName,
  paymentMethodId: paymentMethod.paymentMethodId,
  walletAddress: paymentMethod.walletAddress,
  ...(paymentMethod.networkId && { networkId: paymentMethod.networkId }),
  currencyId: 1,
});

export const PaymentMethodAdapter = {
  toRest,
};
