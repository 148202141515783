<rp-dialog class="full-width" [caption]="'finance.filterSettings' | translate" size="s" (closeDialog)="dialogRef.close()" type="filter">
  <ng-container dialogBody>
    <rp-button
      size="s"
      type="secondary"
      class="offset__bottom"
      fullWidth
      [disabled]="isFormEmpty(dialogData.form?.value)"
      (click)="onReset()"
    >
      <rp-icon [iconName]="icons.Reset" size="s" />
      {{ 'finance.resetFilters' | translate }}
    </rp-button>

    <form class="row" [formGroup]="dialogData.form">
      <rp-input formControlName="id" type="number" label="ID" [iconName]="icons.Search" [placeholder]="'general.select' | translate" />

      <rp-input
        formControlName="userId"
        type="number"
        [iconName]="icons.Search"
        [label]="'finance.webmasterId' | translate"
        [placeholder]="'general.select' | translate"
      />

      <rp-date-picker
        formControlName="range"
        [placeholderStart]="'general.from' | translate"
        [placeholderEnd]="'general.to' | translate"
        [label]="'finance.selectDateOrRange' | translate"
      />

      <rp-select
        formControlName="paymentMethodIds"
        [items]="dialogData.structures()?.paymentMethods"
        [label]="'finance.method' | translate"
        [placeholder]="'general.select' | translate"
        multiple
        displayField="name"
      />

      <rp-select
        formControlName="paymentNetworkIds"
        [items]="dialogData.structures()?.paymentNetworks"
        [label]="'finance.network' | translate"
        [placeholder]="'general.select' | translate"
        multiple
        displayField="name"
      />

      <rp-select
        formControlName="statuses"
        [items]="dialogData.payoutStatuses()"
        [label]="'finance.status' | translate"
        [placeholder]="'general.select' | translate"
        multiple
        displayField="name"
      />
    </form>
  </ng-container>
  <ng-container dialogFooter>
    <rp-button fullWidth [disabled]="isFormEmpty(dialogData.form?.value) || !dialogData.form.valid" (click)="onApply()">
      {{ 'finance.apply' | translate }}
    </rp-button>

    <rp-button (click)="dialogRef.close()" fullWidth>
      {{ 'finance.cancel' | translate }}
    </rp-button>
  </ng-container>
</rp-dialog>
