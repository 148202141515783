import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  OnInit,
  output,
  WritableSignal,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  DatePickerComponent,
  DIALOG_DATA,
  DialogComponent,
  DialogRef,
  IconComponent,
  IconName,
  InputComponent,
  SelectComponent,
} from '@rp/shared/components';
import { Entity, StructureResponse } from '@rp/shared/models';
import { isFormEmpty } from '@rp/utils';

@Component({
  selector: 'rp-filters-admin-dialog',
  standalone: true,
  templateUrl: './filters-admin-dialog.component.html',
  styleUrl: './filters-admin-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    DialogComponent,
    ButtonComponent,
    IconComponent,
    ReactiveFormsModule,
    DatePickerComponent,
    InputComponent,
    SelectComponent,
  ],
})
export class FiltersAdminDialogComponent implements OnInit {
  icons = IconName;
  isFormEmpty = isFormEmpty;

  formValue = output<FormGroup>();
  apply = output();

  dialogData: {
    structures: WritableSignal<StructureResponse>;
    payoutStatuses: WritableSignal<Entity[]>;
    apply: EventEmitter<boolean>;
    form: FormGroup;
  } = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  private _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.dialogData.form.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this.formValue.emit(this.dialogData.form));
  }

  onReset(): void {
    this.dialogData.form.reset();
    this.dialogData.apply.emit();
  }

  onApply(): void {
    this.dialogData.apply.emit();
    this.dialogRef.close();
  }
}
