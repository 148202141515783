import { TableColumn } from '@rp/shared/components';

export const WEBMASTER_FINANCE_TABLE_COLUMN: TableColumn[] = [
  {
    columnDef: 'id',
    header: 'finance.id',
    withSort: true,
  },
  {
    columnDef: 'date',
    header: 'finance.date',
  },
  {
    columnDef: 'method',
    header: 'finance.method',
  },
  {
    columnDef: 'network',
    header: 'finance.network',
  },
  {
    columnDef: 'status',
    header: 'finance.status',
  },
  {
    columnDef: 'amount',
    header: 'finance.amount',
  },
  {
    columnDef: 'action',
    header: 'finance.action',
    align: 'end',
  },
];
