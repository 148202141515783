import * as _ from 'lodash';

const stripEmptyFields = <T>(body: T, shouldStripEmptyArrays = false): Partial<T> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const strippedBody: any = { ...body };

  Object.keys(strippedBody).forEach(key => {
    if (strippedBody[key] === null || strippedBody[key] === undefined || strippedBody[key] === '') {
      delete strippedBody[key];
    }

    if (
      shouldStripEmptyArrays &&
      Array.isArray(strippedBody[key]) &&
      strippedBody[key].length === 0
    ) {
      delete strippedBody[key];
    }
  });

  return strippedBody;
};

const isEmptyObject = <T>(object: T): boolean => {
  if (!object) {
    return false;
  }

  return Object.keys(object).length === 0;
};

const sortKeys = <T>(obj: T): T => {
  const entries = Object.entries(obj).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

  return Object.fromEntries(entries) as T;
};

const objectToQueryString = (params: Record<string, unknown>, arrayKey?: string[]): string => {
  return Object.entries(params)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(item => {
          if (arrayKey?.length) {
            const foundKey = arrayKey.find(k => item[k]);

            return `${key}=${foundKey ? item[foundKey] : item}`;
          }

          return `${key}=${item}`;
        });
      }

      return `${key}=${value}`;
    })
    .join('&');
};

const removeProperty = <T>(obj: T, propToExclude: string): T => {
  if (Array.isArray(obj)) {
    return obj.map(item => removeProperty(item, propToExclude)) as T;
  } else if (_.isObject(obj)) {
    return Object.keys(obj).reduce((acc, key) => {
      if (key !== propToExclude) {
        (acc as any)[key] = removeProperty((obj as any)[key], propToExclude);
      }

      return acc;
    }, {} as T);
  }

  return obj;
};

const isEqualExcept = <T>(obj1: T, obj2: T, propToExclude: string): boolean => {
  const cleanedObj1 = removeProperty(obj1, propToExclude);
  const cleanedObj2 = removeProperty(obj2, propToExclude);

  return _.isEqual(cleanedObj1, cleanedObj2);
};

export const ObjectUtils = {
  stripEmptyFields,
  isEmptyObject,
  sortKeys,
  isEqualExcept,
  objectToQueryString,
};
