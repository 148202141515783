import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatCalendarHeader } from '@angular/material/datepicker';
import { MatButton, MatIconButton } from '@angular/material/button';

@Component({
  selector: 'rp-date-picker-header',
  standalone: true,
  templateUrl: './date-picker-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconButton, MatIconButton, MatButton],
})
export class DatePickerHeaderComponent<D> extends MatCalendarHeader<D> {
  override get periodButtonText(): string {
    const { currentView, activeDate } = this.calendar;

    if (currentView === 'month') {
      const formatedDate = this['_dateAdapter'].format(
        activeDate,
        this['_dateFormats'].display.monthYearLabel,
      );

      return formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);
    }

    if (currentView === 'year') {
      return this['_dateAdapter'].getYearName(activeDate);
    }

    return this['_intl'].formatYearRange(...this['_formatMinAndMaxYearLabels']());
  }
}
