import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';

@Component({
  selector: 'rp-step',
  standalone: true,
  template: `
    <ng-template>
      <ng-content />
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStep, useExisting: StepComponent }],
})
export class StepComponent extends CdkStep {}
