<div class="card__header">
  <ng-content select="[cardHeader]" />
</div>

<div class="card__content">
  <ng-content select="[cardContent]" />
</div>

<div class="card__actions">
  <ng-content select="[cardActions]" />
</div>
