<div>
  <div class="row space__between">
    <div class="font-medium__12">{{ data().date | date: DateFormat.HourWithSeconds }}</div>
    <rp-finance-status [status]="data().status" />
  </div>
  <div class="row space__between">
    <div>
      <div class="label">{{ 'finance.method' | translate }}</div>
      <div>{{ paymentMethods()?.get(data().paymentMethodId) }}</div>
    </div>
    <div>
      <div class="label text__align-right">{{ 'finance.amount' | translate }}</div>
      <div class="text__align-right">{{ data().amountCents | centsToDollarsPipe }}</div>
    </div>
  </div>
</div>
