import { ChangeDetectionStrategy, Component, input, TemplateRef, viewChild } from '@angular/core';

@Component({
  selector: 'rp-cell',
  standalone: true,
  template: `
    <ng-template #cell>
      <ng-content />
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellComponent {
  readonly cellDef = input.required<string>();

  readonly cellTemplate = viewChild.required('cell', { read: TemplateRef<HTMLElement> });
}
