import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CdkStepHeader, StepState } from '@angular/cdk/stepper';

import { MarkComponent } from '../../mark';
import { IconName } from '../../icon';

@Component({
  selector: 'rp-step-header',
  standalone: true,
  templateUrl: './step-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MarkComponent],
})
export class StepHeaderComponent extends CdkStepHeader {
  state = input<StepState>();

  index = input('', {
    transform: (value: number) => String(value + 1),
  });

  label = input<string>();

  selected = input<boolean>();

  active = input<boolean>();

  icons = IconName;
}
