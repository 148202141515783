@if (itemsLength() === 0) {
  <rp-table-placeholder [title]="titlePlaceholder() | translate" [subtitle]="subtitlePlaceholder() | translate">
    <ng-content select="[underPlaceholder]" />
  </rp-table-placeholder>
} @else {
  <div class="table">
    <mat-table class="table__mat-table" [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">
      @for (column of columns(); track column; let i = $index) {
        @if (!column.hidden || !column.hidden()) {
          <ng-container [matColumnDef]="column.columnDef">
            <mat-header-cell
              class="table__header"
              *matHeaderCellDef
              mat-sort-header
              [disabled]="!column.withSort"
              [style]="{
                'min-width': column.width,
                'max-width': column.maxWidth,
                'justify-content': column.align,
                padding: column.padding,
              }"
            >
              <span class="table__header_text">{{ column.header | translate }}</span>
            </mat-header-cell>

            @if (column.actions) {
              <mat-cell
                id="mat-cell-{{ rowIndex + '-' + i }}"
                class="table__cell_actions"
                *matCellDef="let row; let rowIndex = index; dataSource: dataSource"
                [style]="{
                  'min-width': column.width,
                  'max-width': column.maxWidth,
                  'justify-content': column.align,
                  padding: column.padding,
                }"
              >
                @for (action of column.actions; track $index) {
                  <rp-icon [iconName]="action" size="s" (iconClick)="onAction(action, row)" />
                }
              </mat-cell>
            } @else {
              <mat-cell
                id="mat-cell-{{ rowIndex + '-' + i }}"
                class="table__cell"
                *matCellDef="let row; let rowIndex = index; dataSource: dataSource"
                [style]="{
                  'min-width': column.width,
                  'max-width': column.maxWidth,
                  'justify-content': column.align,
                  padding: column.padding,
                }"
              >
                <div rpDynamicTitle class="table__cell_content">
                  <ng-container [ngTemplateOutlet]="rowCellsTemplatesMap[column.columnDef + '-' + rowIndex]" />
                </div>
              </mat-cell>
            }
          </ng-container>
        }
      }

      <ng-container *matHeaderRowDef="displayedColumns()">
        <ng-content select="header" />
        <mat-header-row class="table__header-row"></mat-header-row>
      </ng-container>
      <mat-row [style.background-color]="row.color" class="table__row" *matRowDef="let row; columns: displayedColumns()" />
    </mat-table>

    <mat-paginator
      #paginator
      class="table__paginator"
      [pageSize]="pageSize()"
      [pageIndex]="pageIndex()"
      [length]="itemsLength()"
      [showFirstLastButtons]="showFirstLastButtons()"
      [pageSizeOptions]="pageSizeOptions()"
      (page)="onPageChange($event)"
    />
  </div>
}
