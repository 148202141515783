import { EventEmitter, inject, Injectable, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class HeaderStateService {
  title = signal<string>(null);
  showBack = signal<boolean>(false);
  backRoute = signal<boolean>(false);

  backClick = new EventEmitter<void>();

  private _route = inject(ActivatedRoute);

  reset(): void {
    this.showBack.set(false);
    this.backRoute.set(false);

    this.setTitleFromRoute();
  }

  setTitleFromRoute(): void {
    let child = this._route.firstChild;

    while (child?.firstChild) {
      child = child.firstChild;
    }

    if (child?.snapshot.data) {
      this.title.set(child.snapshot.data['title']);
    }
  }
}
