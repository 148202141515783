import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  private readonly _translate = inject(TranslateService);
  private readonly _destroyRef = inject(DestroyRef);

  constructor() {
    super();
    this.translateLabels();

    this._translate.onLangChange.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.translateLabels();
    });
  }

  translateLabels() {
    this.itemsPerPageLabel = this._translate.instant('paginator.itemsPerPage');
    this.nextPageLabel = this._translate.instant('paginator.nextPageLabel');
    this.previousPageLabel = this._translate.instant('paginator.previousPageLabel');
    this.firstPageLabel = this._translate.instant('paginator.firstPageLabel');
    this.lastPageLabel = this._translate.instant('paginator.lastPageLabel');
    this.changes.next();
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 of ${length}`;
    }
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} of ${length}`;
  };
}
