import { Languages } from '@rp/shared/models';

import { EnvironmentModel } from '../../models/environment.model';

export const environment: EnvironmentModel = {
  production: false,
  uiConfig: {
    header: {
      shouldShowMyBalance: true,
      haveAccountSettings: true,
      havePersonalManager: true,
    },
    sidebar: ['finance', 'statistic', 'myOffers', 'offersWall'],
  },
  apiConfig: {
    adminApiHost: 'https://admin-gateway.rpartners-dev.com/api/v1',
    userApiHost: 'https://user-gateway.rpartners-dev.com/api/v1',
  },
  loginPageUrl: 'https://landing.rpartners-dev.com/auth/sign-in',
  defaultLanguage: Languages.EN,
  languages: [Languages.EN, Languages.RU],
};
