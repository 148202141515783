import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  input,
  signal,
  WritableSignal,
} from '@angular/core';
import { NgClass } from '@angular/common';

import { accordionAnimation } from '@rp/shared/ui';

import { IconComponent, IconName } from '../icon';

@Component({
  selector: 'rp-accordion',
  standalone: true,
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [accordionAnimation],
  imports: [IconComponent, NgClass],
})
export class AccordionComponent {
  readonly isActive = input(false);

  readonly isExpanded: WritableSignal<boolean> = signal(false);

  icons = IconName;

  @HostBinding('class')
  get isActiveClass() {
    return this.isActive() ? 'active' : '';
  }

  toggleAccordion(): void {
    this.isExpanded.set(!this.isExpanded());
  }
}
