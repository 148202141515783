import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'rp-logo',
  standalone: true,
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
  host: {
    '[class]': 'size()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, NgOptimizedImage, NgClass],
})
export class LogoComponent {
  size = input<'s' | 'm'>('s');

  logoClicked = output<void>();

  logoSrc = '/logo.svg';
}
