import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';

import {
  ButtonComponent,
  DatePickerComponent,
  InputComponent,
  SelectComponent,
} from '@rp/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { isFormEmpty } from '@rp/utils';

@Component({
  selector: 'rp-filters-webmaster',
  standalone: true,
  templateUrl: './filters-webmaster.component.html',
  styleUrl: './filters-webmaster.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ButtonComponent,
    DatePickerComponent,
    InputComponent,
    SelectComponent,
    TranslateModule,
    ReactiveFormsModule,
    RouterLink,
  ],
})
export class FiltersWebmasterComponent implements OnInit {
  form = new FormGroup({
    range: new FormControl({
      startDate: null,
      endDate: null,
    }),
  });

  isFormEmpty = isFormEmpty;

  formValue = output<FormGroup>();
  apply = output();

  private _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this.formValue.emit(this.form));
  }
}
