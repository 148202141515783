import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { specialCharactersPattern } from '@rp/shared/models';

import { Validation } from './enums/validation.enum';

export const containsSpecialCharacterValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return specialCharactersPattern.test(control.value)
    ? null
    : { [Validation.ContainsSpecialCharacter]: true };
};
