@let isMobile = isMobile$ | async;

<rp-dialog
  [class.full-width]="isMobile"
  [iconSize]="isMobile ? 's' : 'm'"
  [caption]="'finance.statusAndDetails' | translate"
  (closeDialog)="dialogRef.close()"
>
  <ng-container dialogBody>
    <rp-select
      [formControl]="form.controls.newStatus"
      [label]="'general.labels.status' | translate"
      [placeholder]="'general.labels.status' | translate"
      displayField="name"
      subscriptSizing="dynamic"
      selectedField="id"
      [items]="statuses()"
    />

    <rp-input
      [formControl]="form.controls.comment"
      type="textarea"
      [label]="'general.comment' | translate"
      [placeholder]="'general.comment' | translate"
      [hint]="'errors.maximum_200_symbols' | translate"
      class="height__120"
      maxlength="200"
    />
  </ng-container>
  <ng-container dialogFooter>
    <rp-button class="dialog-footer-button" fullWidth [disabled]="!form.valid" (click)="editStatus()">
      {{ 'general.buttons.save' | translate }}
    </rp-button>

    <rp-button class="dialog-footer-button" fullWidth type="secondary" (click)="dialogRef.close()">
      {{ 'general.buttons.cancel' | translate }}
    </rp-button>
  </ng-container>
</rp-dialog>
