import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { PayoutResponse } from '../models/payout.interface';
import { PayoutHistory } from '../models/payout-history.interface';
import { PayoutRequest } from '../models/payout-request.interface';
import { PayoutBalance } from '../models/payout-balance.interface';

export interface FinanceAdminProvider {
  getPayouts(filters: string): Observable<PayoutResponse>;
  getPayoutsHistory(id: number): Observable<PayoutHistory[]>;
  changePayoutStatus(
    id: number,
    request: { newStatus?: number; comment?: string },
  ): Observable<void>;
  makePayout(request: PayoutRequest): Observable<void>;
  setActivePayoutMethod(id: number, isActive: boolean): Observable<void>;
  getMyBalance(): Observable<PayoutBalance>;
  getPayoutsCSV(filters?: string): Observable<Response>;
}

export const FINANCE_ADMIN_PROVIDER_TOKEN = new InjectionToken<FinanceAdminProvider>(
  'FinanceAdmin',
);
