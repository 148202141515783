import { TableColumn } from '@rp/shared/components';

export const DETAILS_DIALOG_COLUMNS: TableColumn[] = [
  {
    columnDef: 'dateTime',
    header: 'general.dateTime',
  },
  {
    columnDef: 'status',
    header: 'general.labels.status',
  },
  {
    columnDef: 'comment',
    header: 'general.comment',
  },
];
