import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DatePipe } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  CellComponent,
  DialogService,
  IconComponent,
  IconName,
} from '@rp/shared/components';
import { DateFormat, Entity, StructureResponse, TargetApp } from '@rp/shared/models';
import { ClipboardService, StructuresService } from '@rp/shared/services';
import { CentsToDollarsPipe } from '@rp/shared/pipes';

import { DetailsDialogComponent } from '../details-dialog/details-dialog.component';
import { FinanceStatusComponent } from '../finance-status/finance-status.component';
import { EditStatusDialogComponent } from '../edit-status-dialog/edit-status-dialog.component';
import { Payout } from '../../models/payout.interface';
import { FinanceService } from '../../services/finance.service';
import { FINANCE_WEBMASTER_PROVIDER_TOKEN } from '../../providers/finance-webmaster.provider';
import { HttpFinanceWebmasterProvider } from '../../providers/http-finance-webmaster.provider';
import { FINANCE_ADMIN_PROVIDER_TOKEN } from '../../providers/finance-admin.provider';
import { HttpFinanceAdminProvider } from '../../providers/http-finance-admin.provider';

@Component({
  selector: 'rp-details-page',
  standalone: true,
  templateUrl: './details-page.component.html',
  styleUrl: './details-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    IconComponent,
    RouterLink,
    ButtonComponent,
    FinanceStatusComponent,
    CellComponent,
    DatePipe,
    CentsToDollarsPipe,
  ],
  providers: [
    { provide: FINANCE_WEBMASTER_PROVIDER_TOKEN, useClass: HttpFinanceWebmasterProvider },
    { provide: FINANCE_ADMIN_PROVIDER_TOKEN, useClass: HttpFinanceAdminProvider },
    FinanceService,
  ],
})
export class DetailsPageComponent implements OnInit {
  targetApp = TargetApp;
  icons = IconName;
  target: TargetApp;
  DateFormat = DateFormat;

  payout = signal<Payout>(null);
  structures = signal<StructureResponse>(null);
  payoutStatuses = signal<Map<number, string>>(null);

  dialogService = inject(DialogService);
  activatedRoute = inject(ActivatedRoute);
  clipboardService = inject(ClipboardService);
  financeService = inject(FinanceService);
  structuresService = inject(StructuresService);

  private destroyRef = inject(DestroyRef);

  openDetails(): void {
    if (this.target === this.targetApp.Admin) {
      this.dialogService.open(EditStatusDialogComponent, {
        target: TargetApp.Admin,
        financeService: this.financeService,
        statuses: this.payoutStatuses,
        structures: this.structures,
        payout: this.payout(),
      });
    } else {
      this.dialogService.open(DetailsDialogComponent, {
        target: this.target,
        financeService: this.financeService,
        statuses: this.payoutStatuses,
        structures: this.structures,
        payout: this.payout(),
      });
    }
  }

  ngOnInit(): void {
    this.target = this.activatedRoute.snapshot.data['target'];
    const params = this.activatedRoute.snapshot.queryParams;
    this.payout.set({ ...params, status: +params['status'] } as Payout);

    this.structuresService
      .getStructures()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((structures: StructureResponse) => {
        const { payoutStatuses } = structures;
        this.structures.set(structures);
        this.payoutStatuses.set(
          new Map(
            payoutStatuses.map((payoutStatus: Entity) => [payoutStatus.id, payoutStatus.name]),
          ),
        );
      });
  }
}
