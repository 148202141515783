import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'rp-card',
  standalone: true,
  templateUrl: './card.component.html',
  styleUrl: './card.component.scss',
  host: {
    '[class.gap-0]': 'onlyContent()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  onlyContent = input(false, {
    transform: booleanAttribute,
  });
}
