@let control = ngControl.control;

<mat-form-field
  appearance="outline"
  class="rp-input"
  [ngClass]="{
    filled: control.value,
    textarea: type() === 'textarea',
  }"
  [subscriptSizing]="subscriptSizing()"
>
  <mat-label>{{ label() }}</mat-label>

  @if (type() === 'textarea') {
    <textarea matInput cdkTextareaAutosize [formControl]="control" [placeholder]="placeholder()" [errorStateMatcher]="matcher()">
    </textarea>
  } @else {
    <input
      matInput
      [mask]="mask()"
      [decimalMarker]="decimalMarker()"
      [type]="type()"
      [formControl]="control"
      [placeholder]="placeholder()"
      [errorStateMatcher]="matcher()"
    />
  }

  @if (clearable() && control.value) {
    <rp-icon class="icon clickable" matSuffix [iconName]="icons.ResetFilter" size="s" (click)="onClear()" />
  }

  @if (iconName()) {
    <rp-icon class="icon" matSuffix [iconName]="iconName()" size="s" (click)="onIconAction($event)" />
  }

  @if (hint()) {
    <mat-hint mat-hint>{{ hint() }}</mat-hint>
  }

  @if ((control.invalid || (control.dirty && errors().length)) && showErrorMessage()) {
    <mat-error>{{ firstError() | translate }}</mat-error>
  }
</mat-form-field>
