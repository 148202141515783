import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { injectNgControl } from '@rp/utils';

type Appearance = 'default' | 'success';

@Component({
  selector: 'rp-toggle',
  standalone: true,
  templateUrl: './toggle.component.html',
  hostDirectives: [NoopValueAccessorDirective],
  host: {
    '[class]': '"rp-toggle__" + appearance()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSlideToggleModule, ReactiveFormsModule],
})
export class ToggleComponent {
  readonly label = input<string>('');
  readonly appearance = input<Appearance>('default');

  ngControl = injectNgControl();
}
