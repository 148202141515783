import { Component, inject, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AsyncPipe, NgFor } from '@angular/common';

import { Observable } from 'rxjs';

import { triggerChildAnimation } from './animations/trigger-toast-animation';
import { verticalCollapseAnimation } from './animations/vertical-toast-collapse.animation';
import { ToastComponent } from './toast/toast.component';
import { Toast } from './models';
import { ToastService } from './services';

@Component({
  standalone: true,
  templateUrl: './toaster.component.html',
  styleUrl: './toaster.component.scss',
  animations: [verticalCollapseAnimation, triggerChildAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, NgFor, ToastComponent],
})
export class ToasterComponent implements OnInit {
  toasts$: Observable<Toast[]>;

  private readonly _toastService = inject(ToastService);

  ngOnInit(): void {
    this.toasts$ = this._toastService.toasts$;
  }

  close(index: number): void {
    this._toastService.hideToast(index);
  }
}
