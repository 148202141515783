import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { ButtonComponent } from '../../../button';
import { SpinnerDirective } from '../../../spinner';

@Component({
  selector: 'rp-card-info-wrapper',
  standalone: true,
  templateUrl: './card-info-wrapper.component.html',
  styleUrl: './card-info-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TranslateModule, SpinnerDirective],
})
export class CardInfoWrapperComponent {
  shouldShowSpinner = input(false, {
    transform: booleanAttribute,
  });
}
