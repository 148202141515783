export enum Languages {
  EN = 'EN',
  RU = 'RU',
}

export const languageList: Languages[] = Object.values(Languages);

export const languagesLocaleRecord: Record<Languages, string> = {
  [Languages.EN]: 'en',
  [Languages.RU]: 'ru',
};
