import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { lowerCasePattern } from '@rp/shared/models';

import { Validation } from './enums/validation.enum';

export const containsLowerCaseValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return lowerCasePattern.test(control.value) ? null : { [Validation.ContainsLowerCase]: true };
};
