import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@rp/environments';
import { Observable } from 'rxjs';

import { PayoutResponse } from '../models/payout.interface';
import { PayoutHistory } from '../models/payout-history.interface';
import { FinanceAdminProvider } from './finance-admin.provider';
import { PayoutRequest } from '../models/payout-request.interface';
import { PayoutBalance } from '../models/payout-balance.interface';

@Injectable()
export class HttpFinanceAdminProvider implements FinanceAdminProvider {
  private readonly _baseAdminUrl = `${environment.apiConfig.adminApiHost}`;
  private readonly _baseUrl = `${this._baseAdminUrl}/admin/payouts`;

  private readonly _httpClient = inject(HttpClient);

  getPayouts(filters: string): Observable<PayoutResponse> {
    return this._httpClient.get<PayoutResponse>(`${this._baseUrl}?${filters || ''}`);
  }

  getPayoutsHistory(id: number): Observable<PayoutHistory[]> {
    return this._httpClient.get<PayoutHistory[]>(`${this._baseUrl}/${id}/history`);
  }

  changePayoutStatus(
    id: number,
    request: { newStatus?: number; comment?: string },
  ): Observable<void> {
    return this._httpClient.patch<void>(`${this._baseUrl}/${id}/status`, request);
  }

  makePayout(request: PayoutRequest): Observable<void> {
    throw new Error('Method not implemented.');
  }

  setActivePayoutMethod(id: number, isActive: boolean): Observable<void> {
    throw new Error('Method not implemented.');
  }

  getMyBalance(): Observable<PayoutBalance> {
    throw new Error('Method not implemented.');
  }

  getPayoutsCSV(filters?: string): Observable<Response> {
    return this._httpClient.get<Response>(`${this._baseUrl}/csv?${filters || ''}`);
  }
}
