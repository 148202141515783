import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ManagerPayload, ManagersResponse } from '@rp/shared/models';
import { ManagersProvider, MANAGERS_PROVIDER_TOKEN } from '@rp/shared/providers';

@Injectable()
export class ManagersService implements ManagersProvider {
  private _managersProvider = inject(MANAGERS_PROVIDER_TOKEN);

  getManagers(params?: string): Observable<ManagersResponse> {
    return this._managersProvider.getManagers(params);
  }

  createManager(payload: ManagerPayload): Observable<null> {
    return this._managersProvider.createManager(payload);
  }

  updateManager(id: number, payload: ManagerPayload): Observable<null> {
    return this._managersProvider.updateManager(id, payload);
  }
}
