import { inject, Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

import { map, Observable } from 'rxjs';

export enum CommonMediaQueries {
  XS = 320,
  SM = 375,
  MD = 768,
  XL = 1440,
  XXL = 1920,
}

@Injectable({
  providedIn: 'root',
})
export class MediaScreenService {
  private _breakpointObserver = inject(BreakpointObserver);

  mediaMatcher(breakpoint: CommonMediaQueries | number, max?: boolean): Observable<boolean> {
    return this._breakpointObserver
      .observe(max ? `(max-width: ${breakpoint}px)` : `(min-width: ${breakpoint}px)`)
      .pipe(map(({ matches }: BreakpointState) => matches));
  }

  isMatched(breakpoint: CommonMediaQueries | number, max?: boolean): boolean {
    return this._breakpointObserver.isMatched(
      max ? `(max-width: ${breakpoint}px)` : `(min-width: ${breakpoint}px)`,
    );
  }
}
