import { inject, Injectable } from '@angular/core';

import { TargetApp } from '@rp/shared/models';

import { FINANCE_WEBMASTER_PROVIDER_TOKEN } from '../providers/finance-webmaster.provider';
import { FINANCE_ADMIN_PROVIDER_TOKEN } from '../providers/finance-admin.provider';

@Injectable()
export class FinanceService {
  private readonly _financeWebmasterProvider = inject(FINANCE_WEBMASTER_PROVIDER_TOKEN);
  private readonly _financeAdminProvider = inject(FINANCE_ADMIN_PROVIDER_TOKEN);

  getInstance(target: TargetApp) {
    if (target === TargetApp.Admin) {
      return this._financeAdminProvider;
    }

    if (target === TargetApp.Webmaster) {
      return this._financeWebmasterProvider;
    }

    return null;
  }
}
