import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  downloadFile(data: any, fileType: string, fileName: string) {
    const blob = new Blob([data], { type: fileType });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('style', 'display:none');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    a.click();
    URL.revokeObjectURL(url);
  }
}
