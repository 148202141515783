import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

export function isFormEmpty(form: unknown): boolean {
  if (!form) {
    return true;
  }

  const controls = Object.values(form).flatMap(value => {
    if (value instanceof Object) {
      return Object.values(value);
    }

    return value;
  });

  return controls.every(
    val => val === null || val === undefined || (typeof val === 'string' && val.trim() === ''),
  );
}

export function markAllControlsAsDirty(abstractControls: AbstractControl[]): void {
  abstractControls.forEach(abstractControl => {
    if (abstractControl instanceof FormControl) {
      (abstractControl as FormControl).markAsDirty({ onlySelf: true });
    } else if (abstractControl instanceof FormGroup) {
      markAllControlsAsDirty(Object.values((abstractControl as FormGroup).controls));
    } else if (abstractControl instanceof FormArray) {
      markAllControlsAsDirty((abstractControl as FormArray).controls);
    }
  });
}
