import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'rp-balance-card',
  standalone: true,
  templateUrl: './balance-card.component.html',
  styleUrl: './balance-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceCardComponent {
  title = input<string>();
  value = input<string>();
}
