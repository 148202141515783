<div class="rp-radio">
  <mat-radio-group
    class="rp-radio__group"
    [required]="required()"
    [labelPosition]="labelPosition()"
    [name]="groupName()"
    [formControl]="ngControl.control"
  >
    @for (radioButton of radioButtons(); track $index) {
      @if (!isClickableIcon()) {
        <mat-radio-button
          class="rp-radio__button"
          [ngClass]="{
            'no-label': !radioButton.label,
            invalid: ngControl.control.touched && ngControl.control.invalid,
          }"
          disableRipple="true"
          [disabled]="radioButton.disabled"
          [checked]="radioButton.checked"
          [value]="radioButton.value"
        >
          {{ radioButton.label }}

          <rp-icon class="rp-radio__icon" [iconName]="radioButton.iconName || iconName()" size="xs" />
        </mat-radio-button>
      } @else {
        <div class="rp-radio__clickable-icon">
          <mat-radio-button
            class="rp-radio__button"
            [ngClass]="{
              'no-label': !radioButton.label,
              invalid: ngControl.control.touched && ngControl.control.invalid,
            }"
            disableRipple="true"
            [disabled]="radioButton.disabled"
            [checked]="radioButton.checked"
            [value]="radioButton.value"
          >
            {{ radioButton.label }}
          </mat-radio-button>
          <rp-icon class="rp-radio__icon" [iconName]="radioButton.iconName || iconName()" size="xs" (click)="iconClick.emit(radioButton)" />
        </div>
      }
    }
  </mat-radio-group>
</div>
