import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';

import { SpinnerDirective } from '../../../spinner';

@Component({
  selector: 'rp-table-wrapper',
  standalone: true,
  templateUrl: './table-wrapper.component.html',
  styleUrl: './table-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SpinnerDirective],
})
export class TableWrapperComponent {
  shouldShowSpinner = input.required({
    transform: booleanAttribute,
  });
}
