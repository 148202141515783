<button mat-flat-button class="rp-button" disableRipple="true" [ngClass]="classList()" [disabled]="disabled()" (click)="onBtnClick($event)">
  <div class="rp-button__label">
    @if (iconName()) {
      <rp-icon [iconName]="iconName()" [size]="iconSize()" />
    }

    @if (!onlyIcon()) {
      <ng-content />
    }
  </div>
</button>
