import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '@rp/shared/components';

@Injectable({ providedIn: 'root' })
export class HttpErrorHandlerService {
  private _translateService = inject(TranslateService);
  private _toastService = inject(ToastService);

  showErrorMessage(error: HttpErrorResponse): string[] {
    const errorKey = error?.error?.error?.code ?? 'unknown_error';
    const errorMessage = this._translateService.instant(`errors.${errorKey}`);

    this._toastService.showToast({ type: 'error', text: errorMessage });

    return Object.keys(error?.error?.error?.data ?? []);
  }
}
