<div class="rp-stepper">
  <div class="rp-stepper-header-container">
    @for (step of steps; track step; let i = $index, isLast = $last) {
      <ng-container [ngTemplateOutlet]="stepTemplate" [ngTemplateOutletContext]="{ step: step, i: i }" />
      @if (!isLast) {
        <div class="rp-stepper-line" [class.rp-stepper-line-done]="_getIndicatorType(i, step.state) === 'done'"></div>
      }
    }
  </div>

  <div class="rp-stepper-content-container">
    @for (step of steps; track step; let i = $index) {
      <div role="tabpanel" class="rp-stepper-content" [id]="_getStepContentId(i)" [class.rp-stepper-content-inactive]="selectedIndex !== i">
        <ng-container [ngTemplateOutlet]="step.content" />
      </div>
    }
  </div>
</div>

<ng-template let-step="step" let-i="i" #stepTemplate>
  <rp-step-header
    class="rp-step-header"
    (click)="step.select()"
    (mouseenter)="onStepMouseEnter()"
    (keydown)="_onKeydown($event)"
    [attr.aria-posinset]="i + 1"
    [attr.aria-setsize]="steps.length"
    [attr.aria-controls]="_getStepContentId(i)"
    [attr.aria-selected]="selectedIndex === i"
    [attr.aria-label]="step.ariaLabel || null"
    [attr.aria-labelledby]="!step.ariaLabel && step.ariaLabelledby ? step.ariaLabelledby : null"
    [attr.aria-disabled]="isStepNavigable(i) ? null : true"
    [index]="i"
    [tabIndex]="_getFocusIndex() === i ? 0 : -1"
    [id]="_getStepLabelId(i)"
    [active]="isStepActive(i, step)"
    [state]="_getIndicatorType(i, step.state)"
    [selected]="selectedIndex === i"
    [label]="step.label"
  />
</ng-template>
