import { isPlatformBrowser } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly _platformId = inject(PLATFORM_ID);

  setValue<T>(key: string, value: T): void {
    if (!isPlatformBrowser(this._platformId)) {
      return;
    }

    const _value = typeof value === 'string' ? value : JSON.stringify(value);

    localStorage.setItem(key, _value);
  }

  getValue<T>(key: string): T | null {
    if (!isPlatformBrowser(this._platformId)) {
      return null;
    }

    const value = localStorage.getItem(key);

    if (!value) {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch (error) {
      return null;
    }
  }

  getStringValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  removeValue(key: string): void {
    localStorage.removeItem(key);
  }
}
