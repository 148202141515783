import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rp-table-placeholder',
  standalone: true,
  templateUrl: './table-placeholder.component.html',
  styleUrl: './table-placeholder.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class TablePlaceholderComponent {
  title = input<string>();
  subtitle = input<string>();
}
