import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { numbersPattern } from '@rp/shared/models';

import { Validation } from './enums/validation.enum';

export const containsNumberValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return numbersPattern.test(control.value) ? null : { [Validation.ContainsNumber]: true };
};
