import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  OutputEmitterRef,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';

import { ButtonSize, ButtonType } from './models';
import { IconComponent, IconSize } from '../icon';

@Component({
  selector: 'rp-button',
  standalone: true,
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  host: {
    '[class.full-width]': 'fullWidth()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButton, NgClass, IconComponent],
})
export class ButtonComponent {
  readonly click: OutputEmitterRef<MouseEvent> = output<MouseEvent>();

  readonly iconSizes: Record<ButtonSize, IconSize> = {
    s: 'xs',
    m: 's',
  };

  /**
   * Button size
   */
  size = input<ButtonSize>('m');

  /**
   * Button type
   */
  type = input<ButtonType>('primary');

  /**
   * Button icon name
   */
  iconName = input<string>();

  /**
   * If button should have only icon
   */
  onlyIcon = input(false, {
    transform: booleanAttribute,
  });

  /**
   * If button should take full width
   */
  fullWidth = input(false, {
    transform: booleanAttribute,
  });

  /**
   * If button should take full height
   */
  fullHeight = input(false, {
    transform: booleanAttribute,
  });

  /**
   * If button has disabled state
   */
  disabled = input(false, {
    transform: booleanAttribute,
  });

  iconSize = computed<IconSize>(() => this.iconSizes[this.size()]);

  classList = computed<string[]>(() => {
    const classList = [`${this.size()} ${this.type()}`];

    if (this.fullWidth()) {
      classList.push('full-width');
    }

    if (this.fullHeight()) {
      classList.push('full-height');
    }

    if (this.onlyIcon()) {
      classList.push('only-icon');
    }

    return classList;
  });

  onBtnClick(event: MouseEvent): void {
    event.stopPropagation();

    if (!this.disabled()) {
      this.click.emit(event);
    }
  }
}
