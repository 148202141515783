import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '@rp/environments';
import { ManagerPayload, ManagersResponse } from '@rp/shared/models';

import { ManagersProvider } from './managers.provider';

@Injectable()
export class HttpManagersProvider implements ManagersProvider {
  private readonly _httpClient = inject(HttpClient);

  private readonly _apiHost = environment.apiConfig.adminApiHost;
  private readonly _baseUrl = `${this._apiHost}/admin/users/managers/`;

  getManagers(params?: string): Observable<ManagersResponse> {
    return this._httpClient.get<ManagersResponse>(`${this._baseUrl}?${params || ''}`);
  }

  createManager(payload: ManagerPayload): Observable<null> {
    return this._httpClient.post<null>(`${this._baseUrl}`, payload);
  }

  updateManager(id: number, payload: ManagerPayload): Observable<null> {
    return this._httpClient.patch<null>(`${this._baseUrl}${id}/`, payload);
  }
}
