import { ChangeDetectionStrategy, Component, input, output, OutputEmitterRef } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

import { IconSize } from './models';

@Component({
  selector: 'rp-icon',
  standalone: true,
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { style: 'display: inline-flex;' },
  imports: [MatIcon, NgClass],
})
export class IconComponent {
  /**
   * Corresponds to svg file name inside /icons/svg-icons.
   */
  iconName = input.required<string>();

  /**
   * Icon size
   */
  size = input<IconSize>('m');

  iconClick: OutputEmitterRef<MouseEvent> = output<MouseEvent>();

  onIconClick(event: MouseEvent): void {
    this.iconClick.emit(event);
  }
}
