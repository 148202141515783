export enum Validation {
  ContainsSpecialCharacter = 'containsSpecialCharacter',
  InvalidEmail = 'invalidEmail',
  ContainsLowerCase = 'containsLowerCase',
  ContainsNumber = 'containsNumber',
  PasswordMinLength = 'passwordMinLength',
  ContainsUpperCase = 'containsUpperCase',
  PasswordNotMatch = 'passwordNotMatch',
  Required = 'required',
  Pattern = 'pattern',
  GreaterThan = 'greaterThan',
}
