import { inject, Injectable } from '@angular/core';

import { ToastService } from '@rp/shared/components';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  private _toastService = inject(ToastService);

  copy(text: string, successText = 'actions.toast.copiedToClipboard') {
    navigator.clipboard.writeText(text).then(() =>
      this._toastService.showToast({
        text: successText,
        type: 'success',
      }),
    );
  }
}
