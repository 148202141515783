import { ChangeDetectionStrategy, Component, HostBinding, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FinancialStatus } from '../../models/financial-status.enum';

@Component({
  selector: 'rp-finance-status',
  standalone: true,
  templateUrl: './finance-status.component.html',
  styleUrl: './finance-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class FinanceStatusComponent {
  status = input<number>();
  FinancialStatus = FinancialStatus;

  @HostBinding('class')
  get className() {
    switch (this.status()) {
      case FinancialStatus.PAYOUT_REQUEST_STATUS_UNSPECIFIED:
        return 'quaternary';
      case FinancialStatus.PAYOUT_REQUEST_STATUS_NEW:
        return 'quaternary';
      case FinancialStatus.PAYOUT_REQUEST_STATUS_PROGRESS:
        return 'tertiary';
      case FinancialStatus.PAYOUT_REQUEST_STATUS_PAID:
        return 'primary';
      case FinancialStatus.PAYOUT_REQUEST_STATUS_CANCELED:
        return 'secondary';
      default:
        return 'quaternary';
    }
  }
}
