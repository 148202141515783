import {
  ChangeDetectionStrategy,
  Component,
  input,
  OnDestroy,
  OnInit,
  output,
  OutputEmitterRef,
} from '@angular/core';
import { NgClass } from '@angular/common';

import { slideInAnimation, slideOutAnimation } from '@rp/shared/ui';
import { TranslateModule } from '@ngx-translate/core';

import { Toast } from '../models';
import { IconComponent } from '../../icon';

@Component({
  selector: 'rp-toast',
  standalone: true,
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  animations: [slideInAnimation, slideOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, IconComponent, TranslateModule],
})
export class ToastComponent implements OnInit, OnDestroy {
  readonly toast = input.required<Toast>();

  readonly closeToast: OutputEmitterRef<void> = output<void>();

  private _intervalId: ReturnType<typeof setTimeout>;

  ngOnInit(): void {
    this._intervalId = setInterval(() => this.close(), this.toast().duration);
  }

  ngOnDestroy(): void {
    clearInterval(this._intervalId);
  }

  close(): void {
    this.closeToast.emit();
  }
}
