import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { TranslateModule } from '@ngx-translate/core';
import { IconName } from '@rp/shared/components';
import { CentsToDollarsPipe } from '@rp/shared/pipes';

import { BalanceCardComponent } from '../balance-card/balance-card.component';
import { PayoutCardComponent } from '../payout-card/payout-card.component';
import { FINANCE_WEBMASTER_PROVIDER_TOKEN } from '../../providers/finance-webmaster.provider';
import { HttpFinanceWebmasterProvider } from '../../providers/http-finance-webmaster.provider';
import { FINANCE_ADMIN_PROVIDER_TOKEN } from '../../providers/finance-admin.provider';
import { HttpFinanceAdminProvider } from '../../providers/http-finance-admin.provider';
import { FinanceService } from '../../services/finance.service';
import { PayoutBalance } from '../../models/payout-balance.interface';

@Component({
  selector: 'rp-balance-cards',
  standalone: true,
  templateUrl: './balance-cards.component.html',
  styleUrl: './balance-cards.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [BalanceCardComponent, PayoutCardComponent, TranslateModule, CentsToDollarsPipe],
  providers: [
    { provide: FINANCE_WEBMASTER_PROVIDER_TOKEN, useClass: HttpFinanceWebmasterProvider },
    { provide: FINANCE_ADMIN_PROVIDER_TOKEN, useClass: HttpFinanceAdminProvider },
    FinanceService,
  ],
})
export class BalanceCardsComponent implements OnInit {
  icons = IconName;
  financeService = inject(FinanceService);
  activatedRoute = inject(ActivatedRoute);

  balance = signal<PayoutBalance>({} as PayoutBalance);

  getPayouts = output();

  private _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this.financeService
      .getInstance(this.activatedRoute.snapshot.data['target'])
      ?.getMyBalance()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(balance => this.balance.set(balance));
  }
}
