import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

import { IconComponent } from '../icon';
import { MarkType } from './models';

@Component({
  selector: 'rp-mark',
  standalone: true,
  templateUrl: './mark.component.html',
  styleUrl: './mark.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, NgClass],
})
export class MarkComponent {
  type = input<MarkType>('' as MarkType);
  label = input<string>('');
  iconName = input<string>('');
  color = input<string>();
}
