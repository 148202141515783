/**
 * Resolves start of the day for given Date
 * @param date
 */
const startOfTheDay = (date: Date): Date => {
  const startOfTheDay = new Date(date.getTime());
  startOfTheDay.setHours(0, 0, 0, 0);

  return startOfTheDay;
};

/**
 * Resolves end of the day for given Date
 * @param date
 */
const endOfTheDay = (date: Date): Date => {
  const endOfTheDay = new Date(date.getTime());
  endOfTheDay.setHours(23, 59, 59, 999);

  return endOfTheDay;
};

/**
 * Subtracts days from given date
 * @param date
 * @param num
 */
const subtractDays = (date: Date, num: number): Date => {
  const result = new Date(date.getTime());

  result.setDate(result.getDate() - num);

  return result;
};

/**
 * Converts a date to an ISO string without the offset.
 * @param date - The date to convert.
 * @returns The ISO string representation of the date without the offset.
 */
const toISOStringWithoutOffset = (date: Date): string => {
  if (!date) {
    return '';
  }

  return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString();
};

export const DateUtil = {
  startOfTheDay,
  endOfTheDay,
  subtractDays,
  toISOStringWithoutOffset,
};
