import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

import { upperCasePattern } from '@rp/shared/models';

import { Validation } from './enums/validation.enum';

export const containsUpperCaseValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  return upperCasePattern.test(control.value) ? null : { [Validation.ContainsUpperCase]: true };
};
