import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { environment } from '@rp/environments';
import { StructureResponse } from '@rp/shared/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StructuresService {
  private readonly _apiHost = environment.apiConfig.adminApiHost;

  private readonly _http = inject(HttpClient);

  getStructures(): Observable<StructureResponse> {
    return this._http.get<StructureResponse>(`${this._apiHost}/structures/`);
  }
}
