<div class="tabs">
  <ul class="tabs__list">
    @for (tab of tabs; track tab) {
      <li #itemRef class="tabs__item" [class.active]="tab === activeComponent" (click)="activateTab(tab, itemRef)">
        <span class="tabs__title">{{ tab.title() }}</span>
      </li>
    }
  </ul>

  @if (activeComponent?.actionsRef()) {
    <div class="tabs__actions">
      <ng-template [ngTemplateOutlet]="activeComponent.actionsRef()" />
    </div>
  }
</div>

@if (activeComponent?.contentRef()) {
  <div class="tabs__content">
    <ng-template [ngTemplateOutlet]="activeComponent.contentRef()" />
  </div>
}
