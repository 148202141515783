import { booleanAttribute, ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { IconComponent, IconSize } from '../icon';
import { SpinnerDirective } from '../spinner';
import { DialogSize, DialogType } from './models';
import { ButtonComponent } from '../button';

@Component({
  selector: 'rp-dialog',
  standalone: true,
  templateUrl: './dialog.component.html',
  styleUrl: './dialog.component.scss',
  host: {
    class: 'rp-dialog',
    '[class]': '"size_" + size() + " " + "rp-dialog_" + type()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconComponent, SpinnerDirective, ButtonComponent],
})
export class DialogComponent {
  caption = input.required<string>();

  shouldShowCloseButton = input(true, {
    transform: booleanAttribute,
  });

  shouldShowSpinner = input(false, {
    transform: booleanAttribute,
  });

  size = input<DialogSize>('m');

  iconSize = input<IconSize>('m');

  type = input<DialogType>('default');

  closeDialog = output<void>();
}
