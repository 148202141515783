<div class="form__wrapper">
  <form class="form" [formGroup]="form">
    <rp-input
      rpIntegerOnly
      formControlName="id"
      subscriptSizing="dynamic"
      type="number"
      [iconName]="icons.Search"
      [label]="'general.labels.id' | translate"
      [placeholder]="'general.select' | translate"
    />

    <rp-input
      rpIntegerOnly
      formControlName="userId"
      type="number"
      subscriptSizing="dynamic"
      [iconName]="icons.Search"
      [label]="'finance.webmasterId' | translate"
      [placeholder]="'general.select' | translate"
    />

    <rp-date-picker
      formControlName="range"
      subscriptSizing="dynamic"
      [placeholderStart]="'general.from' | translate"
      [placeholderEnd]="'general.to' | translate"
      [label]="'finance.selectDateOrRange' | translate"
    />

    <rp-select
      multiple
      displayField="name"
      subscriptSizing="dynamic"
      formControlName="paymentMethodIds"
      [items]="structures()?.paymentMethods"
      [label]="'finance.method' | translate"
      [placeholder]="'general.select' | translate"
    />

    <rp-select
      multiple
      displayField="name"
      subscriptSizing="dynamic"
      formControlName="paymentNetworkIds"
      [items]="structures()?.paymentNetworks"
      [label]="'finance.network' | translate"
      [placeholder]="'general.select' | translate"
    />

    <rp-select
      multiple
      formControlName="statuses"
      subscriptSizing="dynamic"
      displayField="name"
      [items]="payoutStatuses()"
      [label]="'finance.status' | translate"
      [placeholder]="'general.select' | translate"
    />
  </form>

  <rp-button class="form__button filters" type="secondary" fullWidth [iconName]="icons.Filters" (click)="openFilterDialog()">
    {{ 'finance.filters' | translate }} ({{ form | filterCounter }})
  </rp-button>

  <rp-button class="form__button" type="secondary" [disabled]="isFormEmpty(form?.value) || !form.valid" (click)="apply.emit()">
    {{ 'general.buttons.applyFilters' | translate }}
  </rp-button>
</div>
