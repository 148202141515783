import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@rp/environments';
import { Observable } from 'rxjs';

import { PayoutResponse } from '../models/payout.interface';
import { PayoutRequest } from '../models/payout-request.interface';
import { PayoutHistory } from '../models/payout-history.interface';
import { FinanceWebmasterProvider } from './finance-webmaster.provider';
import { PayoutMethod } from '../models/payout-method.interface';
import { PayoutMethodRequest } from '../models/payout-method-request.interface';
import { PayoutBalance } from '../models/payout-balance.interface';

@Injectable()
export class HttpFinanceWebmasterProvider implements FinanceWebmasterProvider {
  private readonly _baseWebMasterUrl = `${environment.apiConfig.userApiHost}`;
  private readonly _baseUrl = `${this._baseWebMasterUrl}/payouts/webmaster/`;

  private readonly _httpClient = inject(HttpClient);

  getPayouts(filters: string): Observable<PayoutResponse> {
    return this._httpClient.get<PayoutResponse>(`${this._baseUrl}?${filters || ''}`);
  }

  makePayout(request: PayoutRequest): Observable<void> {
    return this._httpClient.post<void>(this._baseUrl, request);
  }

  getPayoutsHistory(id: number): Observable<PayoutHistory[]> {
    return this._httpClient.get<PayoutHistory[]>(`${this._baseUrl}${id}/history`);
  }

  getPayoutsMethod(): Observable<PayoutMethod[]> {
    return this._httpClient.get<PayoutMethod[]>(`${this._baseUrl}/payout_methods/`);
  }

  sendPayoutMethod(request: PayoutMethodRequest): Observable<void> {
    return this._httpClient.post<void>(`${this._baseUrl}/payout_methods/`, request);
  }

  deletePayoutMethod(id: number): Observable<void> {
    return this._httpClient.delete<void>(`${this._baseUrl}/payout_methods/${id}`);
  }

  setActivePayoutMethod(id: number, isActive: boolean): Observable<void> {
    return this._httpClient.post<void>(`${this._baseUrl}/payout_methods/set_active`, {
      isActive,
      paymentMethodId: id,
    });
  }

  changePayoutStatus(
    id: number,
    request: { newStatus?: number; comment?: string },
  ): Observable<void> {
    throw new Error('Method not implemented.');
  }

  getMyBalance(): Observable<PayoutBalance> {
    return this._httpClient.get<PayoutBalance>(`${this._baseWebMasterUrl}/users/balance/`);
  }

  getPayoutsCSV(filters?: string): Observable<Response> {
    return this._httpClient.get<Response>(`${this._baseUrl}/csv?${filters || ''}`);
  }
}
