import { booleanAttribute, ChangeDetectionStrategy, Component, effect, input } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { NgClass } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';

import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { injectNgControl } from '@rp/utils';

@Component({
  selector: 'rp-checkbox',
  standalone: true,
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [NoopValueAccessorDirective],
  imports: [MatCheckbox, ReactiveFormsModule, NgClass],
})
export class CheckboxComponent {
  /**
   * Label for checkbox
   */
  label = input<string>();

  /**
   * If checkbox has disabled state
   */
  disabled = input(false, {
    transform: booleanAttribute,
  });

  /**
   * If checkbox is required
   */
  required = input(false, {
    transform: booleanAttribute,
  });

  ngControl = injectNgControl();

  constructor() {
    effect(() => {
      const { control } = this.ngControl;

      if (this.required()) {
        control.setValidators(Validators.requiredTrue);
      } else {
        control.removeValidators(Validators.requiredTrue);
      }

      if (this.disabled()) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }

  onChange(): void {
    if (this.ngControl.control.untouched) {
      this.ngControl.control.markAsTouched();
    }
  }
}
