/* eslint-disable @typescript-eslint/no-explicit-any */
import { OverlayRef } from '@angular/cdk/overlay';

import { Observable, Subject } from 'rxjs';

/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
export class DialogRef<T> {
  instance: T = null;
  private _afterClosedSubject = new Subject<any>();

  constructor(private _overlayRef: OverlayRef) {}

  /**
   * Closes the overlay. You can optionally provide a result.
   */
  close<T = any>(result?: T) {
    this._overlayRef.dispose();
    this._afterClosedSubject.next(result);
    this._afterClosedSubject.complete();
    this.instance = null;
  }

  /**
   * An Observable that notifies when the overlay has closed
   */
  afterClosed<T = any>(): Observable<T> {
    return this._afterClosedSubject.asObservable();
  }
}
