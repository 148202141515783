<div class="title">{{ 'finance.payout' | translate }}</div>

<form class="row" [formGroup]="form">
  <rp-input
    formControlName="amountCents"
    decimalMarker="."
    mask="separator.2"
    [matcher]="matcher"
    type="text"
    [placeholder]="'finance.amount' | translate"
  />

  <rp-select
    formControlName="walletId"
    [items]="activePaymentMethods()"
    [placeholder]="'finance.method' | translate"
    (onScroll)="onScroll()"
    displayField="walletName"
    selectedField="walletId"
  />
</form>

<rp-button class="margin-top" (click)="makePayout()" [disabled]="!form.valid" fullWidth>
  {{ 'finance.makeRequest' | translate }}
</rp-button>
