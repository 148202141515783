import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  ButtonComponent,
  DatePickerComponent,
  DialogService,
  IconComponent,
  IconName,
  InputComponent,
  IntegerOnlyDirective,
  SelectComponent,
} from '@rp/shared/components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Entity, StructureResponse } from '@rp/shared/models';
import { isFormEmpty } from '@rp/utils';
import { FilterCounterPipe } from '@rp/shared/pipes';

import { FiltersAdminDialogComponent } from '../filters-admin-dialog/filters-admin-dialog.component';

@Component({
  selector: 'rp-filters-admin',
  standalone: true,
  templateUrl: './filters-admin.component.html',
  styleUrl: './filters-admin.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputComponent,
    DatePickerComponent,
    SelectComponent,
    TranslateModule,
    ReactiveFormsModule,
    ButtonComponent,
    IconComponent,
    IntegerOnlyDirective,
    FilterCounterPipe,
  ],
})
export class FiltersAdminComponent implements OnInit {
  structures = input.required<StructureResponse>();

  formValue = output<FormGroup>();
  apply = output<void>();

  form = new FormGroup({
    id: new FormControl(),
    userId: new FormControl(),
    paymentMethodIds: new FormControl(),
    paymentNetworkIds: new FormControl(),
    statuses: new FormControl(),
    range: new FormControl({
      startDate: null,
      endDate: null,
    }),
  });

  icons = IconName;
  isFormEmpty = isFormEmpty;

  payoutStatuses = signal<Entity[]>(null);

  private _dialogService = inject(DialogService);
  private _translateService = inject(TranslateService);
  private _destroyRef = inject(DestroyRef);

  constructor() {
    effect(
      () => {
        this._translateStatuses();
      },
      { allowSignalWrites: true },
    );

    this._translateService.onLangChange
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this._translateStatuses());
  }

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this.formValue.emit(this.form));
  }

  openFilterDialog() {
    this._dialogService.open(FiltersAdminDialogComponent, {
      structures: this.structures,
      payoutStatuses: this.payoutStatuses,
      apply: this.apply,
      form: this.form,
    });
  }

  private _translateStatuses(): void {
    this.payoutStatuses.set(
      this.structures()?.payoutStatuses.map((payoutStatus: Entity) => ({
        id: payoutStatus.id,
        name: this._translateService.instant('finance.' + payoutStatus.name),
      })),
    );
  }
}
