import { ContentChildren, Directive, input, QueryList } from '@angular/core';

import { CellComponent } from '../components/cell.component';

@Directive({
  selector: '[rpRow]',
  standalone: true,
})
export class RowDirective<T> {
  @ContentChildren(CellComponent, { descendants: true }) readonly cells: QueryList<CellComponent>;
  readonly item = input.required<T>();
}
