<ng-container>
  <div class="header">
    <span class="header-title">{{ caption() }}</span>

    @if (shouldShowCloseButton()) {
      <rp-icon iconName="reset" class="close-icon" [size]="iconSize()" (click)="closeDialog.emit()" />
    }
  </div>

  <ng-container *rpSpinner="shouldShowSpinner()">
    <div class="ng-content body">
      <ng-content select="[dialogBody]" />
    </div>

    <div class="ng-content footer">
      <ng-content select="[dialogFooter]" />
    </div>
  </ng-container>
</ng-container>
