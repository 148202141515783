<div class="row">
  <rp-button class="action-button" routerLink="/profile" [queryParams]="{ tab: 'paymentMethod' }">
    {{ 'finance.paymentMethods' | translate }}
  </rp-button>

  <rp-date-picker
    [formControl]="form.controls.range"
    [placeholderStart]="'general.from' | translate"
    [placeholderEnd]="'general.to' | translate"
    [label]="'finance.selectDateOrRange' | translate"
    (onChange)="apply.emit()"
    clearable
    class="date-picker"
  />
</div>
