import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { CdkStepper, STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { StepHeaderComponent } from './step-header';
import { MarkComponent } from '../mark';
import { StepComponent } from './step';

@Component({
  selector: 'rp-stepper',
  standalone: true,
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgTemplateOutlet, MarkComponent, StepHeaderComponent],
  providers: [
    { provide: CdkStepper, useExisting: StepperComponent },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class StepperComponent extends CdkStepper {
  isStepActive(index: number, step: StepComponent): boolean {
    return step.completed || this.selectedIndex === index || !this.linear;
  }

  isStepNavigable(index: number): boolean {
    return (
      !this['_anyControlsInvalidOrPending'](index) &&
      (index >= this.selectedIndex || this.steps.toArray()[index].editable)
    );
  }

  // Need to update cursor on step hover to show that it's clickable or not
  onStepMouseEnter(): void {
    this.selected?._markAsInteracted();
  }
}
