<div class="rp-step-header-item">
  @if (label()) {
    <span class="rp-step-header-label">{{ label() }}</span>
  } @else {
    @if (state() === 'done') {
      <rp-mark class="rp-step" type="success" [iconName]="icons.Apply" />
    } @else {
      <rp-mark class="rp-step" [type]="active() ? 'default' : 'greyOut'" [label]="index()" />
    }
  }
</div>
