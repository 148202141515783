import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';
import { ManagersResponse, ManagerPayload } from '@rp/shared/models';

export interface ManagersProvider {
  getManagers(params?: string): Observable<ManagersResponse>;
  createManager(payload: ManagerPayload): Observable<null>;
  updateManager(id: number, payload: ManagerPayload): Observable<null>;
}

export const MANAGERS_PROVIDER_TOKEN = new InjectionToken<ManagersProvider>('ManagersProvider');
