import { Validators } from '@angular/forms';

import { containsSpecialCharacterValidator } from './special-character.validator';
import { containsLowerCaseValidator } from './lower-case.validator';
import { containsNumberValidator } from './numbers.validator';
import { containsUpperCaseValidator } from './upper-case.validator';
import { passwordMinLengthValidator } from './password-min-length.validator';

export const PASSWORD_VALIDATORS = [
  Validators.required,
  passwordMinLengthValidator,
  containsLowerCaseValidator,
  containsUpperCaseValidator,
  containsNumberValidator,
  containsSpecialCharacterValidator,
];
