@let control = ngControl.control;

<mat-form-field
  class="rp-select-wrapper"
  [class.filled]="!!control.value?.length"
  appearance="outline"
  [subscriptSizing]="subscriptSizing()"
>
  <mat-label>{{ label() }}</mat-label>
  <mat-select
    class="rp-select"
    disableRipple="true"
    [panelClass]="panelClass()"
    [formControl]="control"
    [multiple]="multiple()"
    [placeholder]="placeholder()"
    [compareWith]="compareWithFn"
    (closed)="onClosed()"
    (opened)="onOpened()"
    (openedChange)="$event ? attachScrollEvent() : detachScrollEvent()"
    [errorStateMatcher]="matcher()"
    (selectionChange)="onChange.emit($event)"
  >
    @if (showCountryIcons()) {
      <mat-select-trigger>
        @if (control.value?.length === 1) {
          <span [class]="'fi fi-' + control.value.toString().toLowerCase()"></span>
        }
        {{ control.value }}
      </mat-select-trigger>
    }
    @if (searchable()) {
      <mat-option>
        <ngx-mat-select-search
          class="rp-select-search"
          [formControl]="filterControl"
          [hideClearSearchButton]="true"
          [placeholderLabel]="'general.placeholders.quickSearch' | translate"
          [noEntriesFoundLabel]="'general.placeholders.noMatch' | translate"
        >
          <div class="mat-select-search-custom-header-content">
            <rp-icon class="icon" [class.disabled]="control.disabled" iconName="search" size="xs" />
          </div>
        </ngx-mat-select-search>
      </mat-option>
    }
    @for (item of filteredItems(); track i; let i = $index) {
      <mat-option [value]="!!selectedField() ? item[selectedField()] : item" [class.hide-option]="!!item[hiddenField()]">
        <ng-container *ngTemplateOutlet="itemTpl || defaultItemTpl; context: { $implicit: item }"> </ng-container>
      </mat-option>

      <ng-template #defaultItemTpl let-item>
        @if (icons()[i]; as iconName) {
          <rp-icon class="option-icon" [iconName]="iconName" size="xs" />
        }

        @if (item[selectedField()] && showCountryIcons()) {
          <span [class]="'fi fi-' + item[selectedField()].toString().toLowerCase()"></span>
        }

        @if (translateOptions()) {
          {{ $any(item[displayField()]) || item | translate }}
        } @else {
          {{ item && item[displayField()] ? item[displayField()] : item }}
        }
      </ng-template>
    }

    @let selectedItem = getSelectedItem(control.value);

    @if (selectedItem && searchable()) {
      @if (multiple()) {
        @for (item of control.value; track i; let i = $index) {
          <mat-option class="hide-option" [value]="item">
            <ng-container *ngTemplateOutlet="itemTpl || defaultHiddenItemTpl; context: { $implicit: $any(selectedItem)[i] }">
            </ng-container>
          </mat-option>
        }
      } @else {
        <mat-option class="hide-option" [value]="control.value">
          <ng-container *ngTemplateOutlet="itemTpl || defaultHiddenItemTpl; context: { $implicit: selectedItem }"> </ng-container>
        </mat-option>
      }
    }

    <ng-template #defaultHiddenItemTpl let-item>
      @if (translateOptions()) {
        {{ $any(item[displayField()]) || item | translate }}
      } @else {
        {{ item && item[displayField()] ? item[displayField()] : item }}
      }
    </ng-template>
  </mat-select>

  <div class="rp-select-suffix" matSuffix>
    @if (loading()) {
      <rp-spinner type="select" size="2xs" />
    }

    @if (clearable() && (multiple() ? control.value?.length : control.value)) {
      <rp-icon class="icon" matSuffix [iconName]="iconNames.ResetFilter" size="s" (click)="onClear($event)" />
    }

    <rp-icon class="chevron-down-icon" [class.disabled]="control.disabled" iconName="chevron-down" size="s" />
  </div>

  @if (control.invalid && control.touched && errors().length && showErrorMessage()) {
    <mat-error>{{ firstError() | translate }}</mat-error>
  }
</mat-form-field>
