<div class="card-info">
  <ng-container *rpSpinner="shouldShowSpinner()">
    <div class="ng-content card-info__subheader">
      <ng-content select="[cardInfoSubHeader]" />
    </div>

    <div class="ng-content card-info__content">
      <ng-content select="[cardInfoContent]" />
    </div>
  </ng-container>
</div>
