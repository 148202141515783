import { SelectDropdownItem } from '../interfaces';

export enum UserStatuses {
  New = 'new',
  Approved = 'approved',
  Declined = 'declined',
}

export const userStatusesRecord: Record<UserStatuses, string> = {
  [UserStatuses.New]: 'userStatuses.new',
  [UserStatuses.Approved]: 'userStatuses.approved',
  [UserStatuses.Declined]: 'userStatuses.declined',
};

export const USER_STATUSES_SELECT_ITEMS: SelectDropdownItem<UserStatuses>[] = Object.entries(
  userStatusesRecord,
).map(([value, label]) => ({
  label,
  value: value as UserStatuses,
}));
