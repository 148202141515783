import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

import { SpinnerSize, SpinnerType } from './models';

@Component({
  selector: 'rp-spinner',
  standalone: true,
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
  host: {
    '[class]': 'type()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class SpinnerComponent {
  size = input<SpinnerSize>('m');

  type = input<SpinnerType>('default');
}
